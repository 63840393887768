import React, { useEffect, useState } from "react";
import signInImg from "../../assets/res.jpeg";
import "./sign.css";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { baseUrl } from "../../context/baseUrl";
import ButtonLoader from "../../loader/ButtonLoader";
import { capitalizeWords } from "../../common/toUpperCase";
import maleImage from '../../assets/ps (2).png'; // Default male image path
import femaleImage from '../../assets/ps (1).png'; // Default female image path

import {
  CitySelect,
  CountrySelect,
  StateSelect,
  GetCountries,
  GetState,
  GetCity,
} from "react-country-state-city";

function SignInPage() {
  const [name, setName] = useState("");
  const [nickName, setNickName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [nickNameStatus, setNickNameStatus] = useState({
    available: null,
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [nickLoader, setNickLoader] = useState(false);
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [cityid, setcityid] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [gender, setGender] = useState("");

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);

  const handlesetContry = (ind) => {
    const country = countriesList[ind];
    setCountry(country?.name);
    setCountryid(ind);
    setCountryCode(`+${country?.phone_code}`);
    GetState(country?.id).then((result) => {
      setStateList(result);
    });
  };

  const handlesetState = async (ind) => {
    const state = stateList[ind];
    console.log(state);
    setstateid(ind);
    setState(state?.name);
    try {
      const result = await GetCity(countryid, state.id);
      console.log(result);
      setCityList(result);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  // console.log(cityList, countryid);

  // const handlesetcity = (ind) => {
  //   const city = cityList[ind];
  //   console.log(state);
  //   setcityid(ind)
  //   setCity(state?.name);
  // };

  const navigate = useNavigate();

  const checkNicknameAvailability = async (nickname) => {
    setNickLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/auth/check-nickname`, {
        nickName: nickname,
      });
      setNickNameStatus({
        available: res.data.data.available,
        message: res.data.message,
      });
    } catch (error) {
      console.error(error);
    }
    setNickLoader(false);
  };

  const handleCheckNickname = () => {
    if (nickName) {
      checkNicknameAvailability(nickName);
    }
  };

  const validateFields = () => {
    let errors = {};
    const nameRegex = /^[A-Za-z ]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (!name.trim() || !nameRegex.test(name))
      errors.name = "Name is not valid!";
    if (!nickName.trim()) errors.nickName = "Nick Name is required!";
    if (!email.trim() || !emailRegex.test(email))
      errors.email = "Email is not valid!";
    if (!phoneNo.trim() || !phoneRegex.test(phoneNo))
      errors.phoneNo = "Phone Number is not valid!";
    if (!countryCode.trim()) errors.countryCode = "Please Add Country Code";
    if (!password.trim()) errors.password = "Password is required!";
    if (!confirmPassword.trim())
      errors.confirmPassword = "Confirm Password is required!";
    if (password && confirmPassword && password !== confirmPassword)
      errors.confirmPassword = "Password Mismatch";
    if (!country.trim()) errors.country = "Country is required!";
    if (!state.trim()) errors.state = "State is required!";
    if (!city.trim()) errors.city = "City is required!";
    if (!gender.trim()) errors.gender = "Gender is required!";
    if (password.length <= 7) errors.password = "Minium length is 8";

    return errors;
  };

  async function handleOnSubmit(e) {
    e.preventDefault();
    setErrors({});
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = {
      name,
      nickName,
      email,
      password,
      phone: phoneNo,
      city,
      state,
      country,
      countryCode,
      gender
    };

    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/auth/register`, data);
      const token = res.data.data.token;
      localStorage.setItem("token", token);
      if (res.status === 200) {
        setLoader(false);
        setShowOtpInput(true);
        toast.success("OTP send to mail");
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "An error occurred.");
      setLoader(false);
    }
  }

  async function handleVerifyOtp(e) {
    e.preventDefault();
    const data = {
      email,
      otp,
    };

    try {
      const res = await axios.post(`${baseUrl}/auth/verify-account`, data);
      if (res.status === 200) {
        toast.success("OTP verification successful.");
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "An error occurred.");
    }
  }

  return (
    <>
      <AlertContainer />
      <section className="login">
        <div className="container">
          <div className="text-center"></div>
          <Grid container alignItems={"center"}>
            <Grid item lg={6} xs={12}>
              <div className="heh">
              <div className="sign-in-img">
                <img src={signInImg} alt="" />
              </div>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="login-container">
                <div className="text-left">
                  <h2 className="sign-in-titlefohone">
                    {showOtpInput ? "Verify Account" : "Signup Now!"}
                  </h2>
                  <hr />
                  {showOtpInput && (
                    <form
                      onSubmit={handleVerifyOtp}
                      className="d-flex flex-column"
                    >
                      <div className="sign-in-input-container">
                        <div className="d-flex flex-column">
                          <label htmlFor="" className="input-title">
                          OTP Sent to Email
                          </label>
                          <input
                            className="input-box"
                            name="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            type="text"
                            placeholder="Enter OTP"
                          />
                          <button className="submit-content-btn">
                            Verify Account
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                  {!showOtpInput && (
                    <form onSubmit={handleOnSubmit}>
                      <div className="sign-in-input-container">
                        <div className="d-flex flex-column">
                          <label htmlFor="" className="input-title">
                            Name
                            {errors.name && (
                            <span

                              className="error-message"
                              style={{ color: "red" , marginLeft:'10px' }}
                            >
                              {errors.name}
                            </span>
                          )}
                          </label>
                          <input
                            value={name}
                            name="name"
                            onChange={(e) =>
                              setName(capitalizeWords(e.target.value))
                            }
                            className="input-box"
                            type="text"
                            placeholder="Name"
                            autoComplete="name"
                          />
                        
                        </div>

                        <div className="d-flex flex-column">
                          <label htmlFor="" className="input-title">
                            Nick Name
                            {errors.nickName && (
                            <span
                              className="error-message"
                              style={{ color: "red" ,marginLeft:'10px' }}
                            >
                              {errors.nickName}
                            </span>
                          )}
                          {nickNameStatus.available !== null && (
                            <span
                              style={{
                                marginLeft:'10px',
                                color: nickNameStatus.available
                                  ? "green"
                                  : "red",
                                  
                              }}
                            >
                              {nickNameStatus.message}
                            </span>
                          )}
                          </label>
                          <div
                          className="sing-btnns"
                            style={{ }}
                          >
                            <input
                              value={nickName}
                              name="nickName"
                              onChange={(e) => setNickName(e.target.value)}
                              autoComplete="nickName"
                              className="input-box"
                              type="text"
                              placeholder="Nick Name"
                            />
                            <button
                              type="button"
                              onClick={handleCheckNickname}
                              style={{
                                marginLeft: "10px",
                                
                                cursor: "pointer",
                              }}
                            >
                              {nickLoader ? <ButtonLoader /> : "Check"}
                            </button>
                          </div>
                        
                        </div>

                        <div className="d-flex flex-column">
                          <label htmlFor="" className="input-title">
                            Email {errors.email && (
                            <span
                              className="error-message"
                              style={{ color: "red", marginLeft:'10px' }}
                            >
                              {errors.email}
                            </span>
                          )}
                          </label>
                          <input
                            className="input-box"
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                          />
                         
                        </div>

                        <div className="d-flex flex-column">
                          <label htmlFor="" className="input-title">
                            Country
                            {errors.country && (
                            <span
                              className="error-message"
                              style={{ color: "red",marginLeft:'10px' }}
                            >
                              {errors.country}
                            </span>
                          )}
                          </label>
                          <select
                            className="input-box"
                            onChange={(e) => handlesetContry(e.target.value)}
                            value={countryid}
                          >
                            {countriesList &&
                              countriesList.map((item, index) => (
                                <option key={index} value={index}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                          {/* <input
                            className="input-box"
                            name="country"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            type="text"
                            placeholder="Country"
                          /> */}
                        
                        </div>
                        <div className="d-flex flex-column">
                          <label htmlFor="" className="input-title">
                            Gender
                            {errors.gender && (
                            <span
                              className="error-message"
                              style={{ color: "red", marginLeft:'10px' }}
                            >
                              {errors.gender}
                            </span>
                          )}
                          </label>
                          <select
                            className="input-box"
                            onChange={(e) => setGender(e.target.value)}
                            value={gender}
                          >
                            <option value="">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                            {/* Display Image Based on Gender */}
      {/* {gender && (
        <div className="gender-image-container">
        <img 
        src={gender === 'Male' ? maleImage : femaleImage}
        alt={gender}
        className="gender-image"
      />
        </div>
      )} */}
                      
                        </div>
                        <div className="d-flex flex-column">
                          <label htmlFor="" className="input-title">
                            State   {errors.state && (
                            <span
                              className="error-message"
                              style={{ color: "red" , marginLeft:'10px'}}
                            >
                              {errors.state}
                            </span>
                          )}
                          </label>
                          <select
                            className="input-box"
                            onChange={(e) => handlesetState(e.target.value)}
                            value={stateid}
                          >
                            {stateList &&
                              stateList.map((item, index) => (
                                <option key={index} value={index}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                          {/* <input
                            className="input-box"
                            name="state"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            type="text"
                            placeholder="State"
                          /> */}
                       
                        </div>
                        <div className="d-flex flex-column">
                          <label htmlFor="" className="input-title">
                            City{errors.city && (
                            <span
                              className="error-message"
                              style={{ color: "red",marginLeft:'10px' }}
                            >
                              {errors.city}
                            </span>
                          )}
                          </label>
                          {/* <select
                            className="input-box"
                            onChange={(e) => handlesetcity(e.target.value)}
                            value={cityid}
                          >
                            {cityList &&
                              cityList.map((item, index) => (
                                <option key={index} value={index}>
                                  {item.name}
                                </option>
                              ))}
                          </select> */}
                          <input
                            className="input-box"
                            name="city"
                            value={city}
                            onChange={(e) =>
                              setCity(capitalizeWords(e.target.value))
                            }
                            type="text"
                            placeholder="City"
                          />
                          
                        </div>

                        <div className="d-flex flex-column">
                          <label htmlFor="" className="input-title">
                            Country Code
                            {errors.countryCode && (
                            <span
                              className="error-message"
                              style={{ color: "red" , marginLeft:'10px' }}
                            >
                              {errors.countryCode}
                            </span>
                          )}
                          </label>
                          <input
                            className="input-box"
                            name="countryCode"
                            autoComplete="countryCode"
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            type="text"
                            placeholder="+ CountryCode"
                            disabled
                          />
                          
                        </div>
                        <div className="d-flex flex-column">
                          <label htmlFor="" className="input-title">
                            Phone Number
                            {errors.phoneNo && (
                            <span
                              className="error-message"
                              style={{ color: "red" , marginLeft:'10px'}}
                            >
                              {errors.phoneNo}
                            </span>
                          )}
                          </label>
                          <input
  className="input-box"
  name="phoneNo"
  autoComplete="phoneNo"
  value={phoneNo}
  maxLength={12}
  onChange={(e) => {
    const phoneInput = e.target.value;
    if (/^\d*$/.test(phoneInput)) {
      setPhoneNo(phoneInput);
    }
  }}
  type="tel"
  placeholder="Phone Number"
/>
                       
                        </div>
                        <div className="d-flex flex-column">
                          <label htmlFor="" className="input-title">
                            Password
                            {errors.password && (
                            <span
                              className="error-message"
                              style={{ color: "red", marginLeft:'10px' }}
                            >
                              {errors.password}
                            </span>
                          )}
                          </label>
                          <div
                           className="sing-btnns"
                          >
                            <input
                              className="input-box"
                              name="password"
                              autoComplete="current-password"
                              placeholder="Enter Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              type={showPassword ? "text" : "password"}
                            />
                            <button
                              type="button"
                              onClick={() => setShowPassword(!showPassword)}
                              style={{
                                marginLeft: "10px",
                                
                                cursor: "pointer",
                              }}
                            >
                              {showPassword ? "Hide" : "Show"}
                            </button>
                          </div>
                          
                        </div>
                        <div className="d-flex flex-column">
                          <label htmlFor="" className="input-title">
                            Confirm Password     {errors.confirmPassword && (
                            <span
                              className="error-message"
                              style={{ color: "red" , marginLeft:'10px' }}
                            >
                              {errors.confirmPassword}
                            </span>
                          )}
                          </label>
                          <input
                            className="input-box"
                            name="confirmPassword"
                            autoComplete="confirm-password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type={showPassword ? "text" : "password"}
                          />
                      
                        </div>
                      </div>
                      <button className="submit-content-btn">
                        {loader ? <ButtonLoader /> : "Submit"}
                      </button>
                      <p className="mt-4">
                        Doesn't have an account?<Link  to="/login" style={{ fontWeight:'500'}}> Login Now</Link>
                        {/* <Link
                         
                          className="submit-content-btn"
                          style={{ padding: "6px 26px" ,marginLeft:'15px'}}
                        >
                         
                        </Link> */}
                      </p>
                    </form>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      {loader && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
    </>
  );
}

export default SignInPage;
