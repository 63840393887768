import React, { useContext, useEffect, useState } from "react";
import leftBook from "../../assets/left-book.png";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarContext from "../../context/SidebarContext";
import axios from "axios";
import { baseUrl } from "../../context/baseUrl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGraduationCap} from "@fortawesome/free-solid-svg-icons";
function LeftSidebar() {
  const pathName = useLocation();
  const side = useContext(SidebarContext);
  const { setLeftSidebar } = side;
  const navigate = useNavigate();

  const [categoryData, setCategoryData] = useState([]);
  const [contentId, setContentId] = useState("");

  // Function to handle navigation to "Add Content"
  function AddContent() {
    navigate("/add-content");
  }

  // Function to fetch category data based on ID
  async function getCategory(Id) {
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/types/getatype/${Id}`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setCategoryData([res.data.data]);
    } catch (e) {
      console.error(e);
    }
  }

  // Update contentId based on current pathname
  useEffect(() => {
    if (pathName.pathname === "/colleges") {
      setContentId("64a7aabb165fde14c3536c28");
    } else if (pathName.pathname === "/book-review") {
      setContentId("64a7ab9e165fde14c3536c38");
    } else if (pathName.pathname === "/blog") {
      setContentId("64a7aaee165fde14c3536c2c");
    } else if (pathName.pathname === "/reader-club") {
      setContentId("64a7abb8165fde14c3536c3b");
    } else if (pathName.pathname === "/writer-club") {
      setContentId("64a7abd4165fde14c3536c3e");
    } else if (pathName.pathname === "/quotes") {
      setContentId("651d37e81adce5a850d049ce");
    }
    // getCategory(contentId);
  }, [pathName.pathname]);


  
  const getPageName = () => {
    const path = pathName.pathname;

    // Log the path to debug
    console.log("Current Path:", path);
  
    // Normalize path (removes trailing slash)
    const normalizedPath = path.replace(/\/$/, "");
  
    if (normalizedPath === "/") return "Home";
    if (normalizedPath === "/collage-review") return "Collage Review";
    if (normalizedPath === "/colleges") return "Colleges";
    if (normalizedPath.startsWith("/book/book-detail/")) return "Book Detail"; // Handling dynamic book detail paths
    if (normalizedPath.startsWith("/collage-detail")) return "Colleges Detail"; // Handling dynamic category paths
    
    if (normalizedPath.startsWith("/book/review-customer")) return "Book Detail"; // Handling dynamic category paths
    if (normalizedPath.startsWith("/book-question")) return "Book Detail"; // Handling dynamic category paths
    
    if (normalizedPath.startsWith("/college/college-customer")) return "College Detail"; // Handling dynamic category paths
    if (normalizedPath.startsWith("/collage-question")) return "College Detail"; // Handling dynamic category paths


    if (path === "/customer-review") return "Customer Review";
    if (path === "/acadamy-pages") return "Academy Pages";
    if (path === "/answer") return "Answer";
    if (path === "/sell") return "Sell";
    if (path === "/productlist") return "Product List";
    if (path === "/members") return "Members";
    if (normalizedPath.startsWith("/member/")) return "Members"; // Handling dynamic category paths
    if (path === "/blog") return "Blog";
    if (path === "/book-review") return "Book Review";
    if (path === "/quotes") return "Quotes";
    if (path === "/reader-club") return "Reader Club";
    if (path === "/writer-club") return "Writer Club";
    if (path === "/add-content") return "Add Content";
    if (path === "/cart") return "Cart";
    if (path === "/profile") return "Profile";
    if (path === "/login") return "Login";
    if (path === "/forgot") return "Forgot Password";
    if (path === "/sign-in") return "Sign In";
    if (path === "/contact-us") return "Contact Us";
    if (path === "/mypage") return "My Page";
  
    // Regular expressions to match dynamic routes
    if (/^\/qna-ans\/\d+$/.test(path)) return "Q&A Answer";
    if (/^\/rnw-ans\/\d+$/.test(path)) return "RnW Answer";
    if (/^\/book-question(\/\d+)?$/.test(path)) return "Book Question";
    if (/^\/collage-question\/\d+$/.test(path)) return "Collage Question";
    if (/^\/editsell\/\d+$/.test(path)) return "Edit Sell";
    if (/^\/member\/\d+$/.test(path)) return "Member Detail";
    if (/^\/blog\/blog-detail\/\w+$/.test(path)) return "Blog Detail";
    if (/^\/quotes\/quotes-detail\/\w+$/.test(path)) return "Quotes Detail";
    if (/^\/collage-detail\/\d+$/.test(path)) return "College Detail";
    if (/^\/product\/review-customer\/\d+$/.test(path)) return "Product Detail";
    if (/^\/book\/book-detail\/\d+$/.test(path)) return "Book Detail";
    if (/^\/collage\/review-customer\/\d+$/.test(path)) return "Review Customer";
    if (/^\/book\/review-customer\/\d+$/.test(path)) return "Book Reviews";
    if (/^\/college\/college-customer\/\d+$/.test(path)) return "College Review";
    if (/^\/reader-club\/reader-club-detail\/\w+$/.test(path)) return "Reader Club Detail";
    if (/^\/writer-club\/writer-club-detail\/\w+$/.test(path)) return "Writer Club Detail";
    if (/^\/edit-profile\/\w+$/.test(path)) return "Edit Profile";
  
    return "Home";
  };

  return (
  


    <>
  
      {/* Breadcrumb Navigation */}
      <div className="d-flex flex-column add-content-title align-item-center mt-3 nav-get">
        <p><a href="/">Home </a>/ <b>{getPageName()}</b></p>
        <div
          className="text-center mb-3 left-side-add-icon"
          onClick={AddContent}
        >
          <i className="fa fa-solid fa-plus"></i>
        </div>
        <div className="text-center">
          <a href="/add-content">ADD CONTENT</a>
        </div>
      </div>

      {/* Navigation Links */}
      <div
        className={`d-flex add-content-list align-item-center ${
          pathName.pathname === "/members" ? "active-header" : ""
        }`}
      >
        <a
          href="/#/members"
          onClick={() => false}
          className={pathName.pathname === "/members" ? "active" : ""}
        >
          <i className="bi bi-people-fill"></i> Members
        </a>
      </div>
      <div
        className={`d-flex add-content-list align-item-center ${
          pathName.pathname === "/book-review" ? "active-header" : ""
        }`}
      >
        <a
          href="/#/book-review"
          onClick={() => setLeftSidebar(false)}
          className={pathName.pathname === "/book-review" ? "active" : ""}
        >
          <i className="bi bi-book-half"></i> Books
        </a>
      </div>
      <div
        className={`d-flex add-content-list align-item-center ${
          pathName.pathname === "/blog" ? "active-header" : ""
        } ${pathName.pathname === "/blog/blog-detail" ? "active" : ""}`}
      >
        <a
          href="/#/blog"
          onClick={() => setLeftSidebar(false)}
          className={pathName.pathname === "/blog" ? "active" : ""}
        >
          <i className="bi bi-substack"></i> Blogs
        </a>
      </div>
      <div
        className={`d-flex add-content-list align-item-center ${
          pathName.pathname === "/quotes" ? "active-header" : ""
        }`}
      >
        <a
          href="/#/quotes"
          onClick={() => setLeftSidebar(false)}
          className={pathName.pathname === "/quotes" ? "active" : ""}
        >
          <i className="bi bi-chat-dots-fill"></i> Quotes
        </a>
      </div>
      <div
        className={`d-flex add-content-list align-item-center ${
          pathName.pathname === "/colleges" ? "active-header" : ""
        }`}
      >
        <a href="/#/colleges" onClick={() => setLeftSidebar(false)}>
        <i>
        <FontAwesomeIcon icon={faGraduationCap} style={{marginLeft:'-2px'}}/>
        </i>
        <p style={{marginBottom:'0', marginLeft:'-3px'}}>

Colleges
        </p>
        </a>
      </div>
      <div
        className={`d-flex add-content-list align-item-center ${
          pathName.pathname === "/reader-club" ? "active-header" : ""
        }`}
      >
        <a
          href="/#/reader-club"
          onClick={() => setLeftSidebar(false)}
          className={pathName.pathname === "/reader-club" ? "active" : ""}
        >
          <i  className="fa fa-regular fa-book"></i> Readers Club
        </a>
      </div>
      <div
        className={`d-flex add-content-list align-item-center ${
          pathName.pathname === "/writer-club" ? "active-header" : ""
        }`}
      >
        <a
          href="/#/writer-club"
          onClick={() => setLeftSidebar(false)}
          className={pathName.pathname === "/writer-club" ? "active" : ""}
        >
          <i className="bi bi-pencil-square"></i> Writers Club
        </a>
      </div>

      {/* Placeholder for Left Book Image */}
      <div className="text-center">
        <img src={leftBook} style={{ marginTop: "70px" }} alt="Left Book" />
      </div>
    </>
   

  );
}

export default LeftSidebar;
