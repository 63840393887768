import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import ReaderCard from "../components/reader/ReaderCard";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import TopWriter from "../components/answer/TopWriter";
import RecentWriter from "../components/answer/RecentWriter";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import adImg from "../assets/ad-img.png";
import SidebarContext from "../context/SidebarContext";
import CollageAddQuestion from "../components/AddQuestionModal/CollageAddQuestion";
import { baseUrl } from "../context/baseUrl";
import { capitalizeParagraph, capitalizeWords } from "../common/toUpperCase";

function CollageQ() {
  const { id } = useParams();
  const [IsOpen, setIsOpen] = useState(false);
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const [qnaData, setQnaData] = useState([]);
  const navigate = useNavigate();
  const [content, setContent] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  console.log(content);
  useEffect(() => {
    async function fetchContent() {
      try {
        const response = await axios.get(
          `${baseUrl}/content/getacontent/${id}`
        );
        setContent(response?.data?.data); // Assuming the response.data is the content object
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    }

    fetchContent();
  }, [id]);

  async function getQnAData(contentId) {
    try {
      const res = await axios.get(`${baseUrl}/qna/bycontent/${contentId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setQnaData(res?.data?.data?.qna);
    } catch (error) {
      console.error("Error fetching Q&A data:", error);
    }
  }
  useEffect(() => {
    getQnAData(id);
  }, [id]);

  function handleSearch(event) {
    setSearchQuery(event.target.value);
  }

  const filteredQnAData = qnaData.filter((question) =>
    question?.question?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // var allReader = null;
  // if (reader !== null) {
  //   allReader = reader.map((element, index) => {
  //     // .likes.includes("userId")?liked:notliked
  //     return (
  //       <>
  //         <div key={index}>
  //           <ReaderCard
  //             getBlog={getBlogs()}
  //             author={element.nickName}
  //             readerId={element._id}
  //             title={element.heading}
  //             description={element.description}
  //             date={element.createdAt}
  //             likes={element.likes}
  //             likesCount={element.likesCount}
  //             comments={element.commentArray}
  //             like={element}
  //           />
  //         </div>
  //       </>
  //     );
  //   });
  // }

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          {/* <div className="d-flex align-items-center path-box">
            <p className="path-name m-0">Reader Club</p>
            <img src={rightArrow} style={{ height: "20px"}} alt="" />
          </div> */}
          <div
            className="d-flex justify-content-between path-box"
            style={{ paddingRight: "0" , gap:'20px'}}
          >
            {/* <div className="d-flex align-items-center">
              <p
                className="path-name m-0"
                onClick={() => {
                  navigate("/book-review");
                }}
              >
                Home /
              </p>
              <p className="path-name-bold m-0">&nbsp; College Question</p>
            </div> */}
            <div className="search-container" style={{padding:"auto"}}>
              <input
                type="text"
                placeholder="Search questions..."
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <button className="add-question" onClick={() => setIsOpen(true)}>
              Add Question
            </button>
          </div>
          <br/>
          <div className="d-flex">
            <img src={content?.displayImage} alt="" width={"70px"} height={"70px"}/>
            <div className="review-customer-box">
            <p className="book-customer-text mb-2">
                {capitalizeWords(content?.nickName)},{" "}
                {capitalizeWords(content?.userId?.country)}
              </p>
              <h2 className="book-customer-title">
                {/* World’s Greatest Books For Personal Growth & Wealth (Set of 4
                Books): Perfect Motivational Gift Set Paperback – 1 August 2019 */}
                {capitalizeWords(content?.heading)}
              </h2>
              <p className="book-customer-text mb-2">
                {capitalizeWords(content?.state)},{" "}
                {capitalizeWords(content?.country)}
              </p>
            </div>
          </div>
          <br />
          {/* {allReader} */}
          {filteredQnAData?.length > 0 &&
            filteredQnAData?.map((question) => (
              // <div key={question._id}>
              <ReaderCard
                author={question?.userId?.nickName}
                readerId={question.userId?._id}
                title={question.question}
                description="Add description here if needed"
                date={question.createdAt}
                data={question}
                contentId={id}
                getQnAData={getQnAData}
              />
              // </div>
            ))}
        </div>
        <div className="right-box">
          {/* <h2 className="right-title text-center">Reader review</h2>
          <div className="suggest-box">
            <TopWriter />
            <TopWriter />
            <TopWriter />
          </div>
          <div className="suggest-box">
            <ul
              className="nav nav-tabs justify-content-around"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Top Reader
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Recent Reader
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <TopWriter />
                <TopWriter />
                <TopWriter />
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <RecentWriter />
                <RecentWriter />
                <RecentWriter />
                <RecentWriter />
              </div>
            </div>
          </div> */}
          <h2 className="recent-title text-center">Place for ad</h2>
          <img src={adImg} alt="" />
        </div>
      </section>
      {IsOpen && (
        <CollageAddQuestion
          setIsOpen={setIsOpen}
          contentId={id}
          getQnAData={getQnAData}
        />
      )}
    </>
  );
}

export default CollageQ;
