import React from 'react'
import star from '../assets/star.svg'
import EmptyStar from "../assets/star-empty.svg"


function Rating({ rating = 5 }) {
  const totalStars = 5;

  return (
    <ul className="rating-star" style={{ listStyle: 'none', padding: 0, display: 'flex', gap: '4px' }}>
      {[...Array(totalStars)].map((_, index) => (
        <li key={index}>
          <i
            className={index < rating ? "bi bi-star-fill" : "bi bi-star"}
            style={{ fontSize: '20px', color: '#FFD700' }} // Gold color for stars
          ></i>
        </li>
      ))}
    </ul>
  );
  // return (
  //   <>
  //   <ul className='rating-star'>
  //       <li><img src={star} alt="" /></li>
  //       <li><img src={star} alt="" /></li>
  //       <li><img src={star} alt="" /></li>
  //       <li><img src={star} alt="" /></li>
  //       <li><img src={star} alt="" /></li>
  //   </ul>
  //   </>
  // )
}

export default Rating