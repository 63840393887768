import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Rating from '../Rating';
import { baseUrl } from '../../context/baseUrl';
import { useNavigate } from 'react-router-dom';

function RightCustomerCard({ contentType }) {
  const navigate = useNavigate()
  const [data, setData] = useState([]);

  const handlenavigate = (id)=>{
    if(!id){
      return 
    }
    if(contentType=="Colleges"){
      navigate(`/collage-detail/${id}`)
    }else if(contentType=="Books"){
      navigate(`/book/book-detail/${id}`)
    }
  }
  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/content/getallcontent/rating?contentType=${contentType}`);
      if (res) {
        const sortedData = res.data.data.sort((a, b) => b.rating - a.rating);
        setData(sortedData);
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  };

  useEffect(() => {
    if (contentType) {
      getData();
    }
  }, [contentType]);

  return (
    <>
      {data.map((item) => (
        <div key={item._id} style={{}} className='d-flex right-customer-card' onClick={()=>handlenavigate(item?._id)} >
          <img src={item.displayImage} alt={item.heading} style={{ width:'100px' , cursor:'pointer' }} />
          <div className='right-customer-content'>
            <h6 className='m-0'>{item.heading}</h6>
            <h5 className='m-0'>{item.author}</h5>
            <div className='d-flex rating-total '>
              <Rating rating={(item?.rating||0)/(item?.noOfRates)} />
              <p>{item.noOfRates} ratings</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default RightCustomerCard;
