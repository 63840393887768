import React, { useContext, useState } from "react";
import logo from "../../assets/newlogo.jpeg";
import searchIcon from "../../assets/search-Icon.png";
import profileIcon from "../../assets/profile-icon.png";
import likeIcon from "../../assets/like-icon.png";
import cartIcon from "../../assets/cart-icon.png";
import "./header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SidebarContext from "../../context/SidebarContext";
import { Snackbar } from "@mui/material";
import axios from "axios";
import LoginContext from "../../context/login/LoginContext";
import { baseUrl } from "../../context/baseUrl";
import NewPath from "./NewPath";

function Header() {
  const side = useContext(SidebarContext);
  const login = useContext(LoginContext);
  const { loggedIn } = login;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const { openLeftSidebar } = side;
  const [toggleSideBar, setSidebarToggle] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const initialValue = {
    content_type: "",
    category: "",
    sub_category: "",
    heading: "",
    description: "",
    author: "",
  };

  const [formData, setFormData] = useState(initialValue);

  const pathName = useLocation();

  function openSidebar() {
    setSidebarToggle(!toggleSideBar);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  async function handleOnSubmit() {
    const {
      content_type,
      category,
      sub_category,
      heading,
      description,
      author,
    } = formData;
    if (content_type === "") return setErrorMessage("Content Type is Required");
    if (category === "") return setErrorMessage("Category is Required");
    if (sub_category === "") return setErrorMessage("Sub Category is Required");
    if (heading === "") return setErrorMessage("heading is Required");
    if (author === "") return setErrorMessage("Author Type is Required");
    if (description === "")
      return setErrorMessage("Description Type is Required");
    try {
      const body = JSON.stringify({
        content_type: formData.content_type,
        category: formData.category,
        sub_category: formData.sub_category,
        heading: formData.heading,
        description: formData.description,
        author: formData.author,
      });
      const res = await axios({
        method: "post",
        url: `${baseUrl}/content/create`,
        data: body,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setFormData(initialValue);
    } catch (e) {
      setErrorMessage("Failed to submit data");
    }
  }

  // Logout function to clear token and redirect
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };


  const getPageName = () => {
    const path = pathName.pathname;

    // Log the path to debug
    console.log("Current Path:", path);
  
    // Normalize path (removes trailing slash)
    const normalizedPath = path.replace(/\/$/, "");
  
    if (normalizedPath === "/") return "Home";
    if (normalizedPath === "/collage-review") return "Collage Review";
    if (normalizedPath === "/colleges") return "Colleges";
    if (normalizedPath.startsWith("/book/book-detail/")) return "Book Detail"; // Handling dynamic book detail paths
    if (normalizedPath.startsWith("/collage-detail")) return "Colleges Detail"; // Handling dynamic category paths
    
    if (normalizedPath.startsWith("/book/review-customer")) return "Book Detail"; // Handling dynamic category paths
    if (normalizedPath.startsWith("/book-question")) return "Book Detail"; // Handling dynamic category paths
    
    if (normalizedPath.startsWith("/college/college-customer")) return "College Detail"; // Handling dynamic category paths
    if (normalizedPath.startsWith("/collage-question")) return "College Detail"; // Handling dynamic category paths


    if (path === "/customer-review") return "Customer Review";
    if (path === "/acadamy-pages") return "Academy Pages";
    if (path === "/answer") return "Answer";
    if (path === "/sell") return "Sell";
    if (path === "/productlist") return "Product List";
    if (path === "/members") return "Members";
    if (normalizedPath.startsWith("/member/")) return "Members"; // Handling dynamic category paths
    if (path === "/blog") return "Blog";
    if (path === "/book-review") return "Book Review";
    if (path === "/quotes") return "Quotes";
    if (path === "/reader-club") return "Reader Club";
    if (path === "/writer-club") return "Writer Club";
    if (path === "/add-content") return "Add Content";
    if (path === "/cart") return "Cart";
    if (path === "/profile") return "Profile";
    if (path === "/login") return "Login";
    if (path === "/forgot") return "Forgot Password";
    if (path === "/sign-in") return "Sign In";
    if (path === "/contact-us") return "Contact Us";
    if (path === "/mypage") return "My Page";
  
    // Regular expressions to match dynamic routes
    if (/^\/qna-ans\/\d+$/.test(path)) return "Q&A Answer";
    if (/^\/rnw-ans\/\d+$/.test(path)) return "RnW Answer";
    if (/^\/book-question(\/\d+)?$/.test(path)) return "Book Question";
    if (/^\/collage-question\/\d+$/.test(path)) return "Collage Question";
    if (/^\/editsell\/\d+$/.test(path)) return "Edit Sell";
    if (/^\/member\/\d+$/.test(path)) return "Member Detail";
    if (/^\/blog\/blog-detail\/\w+$/.test(path)) return "Blog Detail";
    if (/^\/quotes\/quotes-detail\/\w+$/.test(path)) return "Quotes Detail";
    if (/^\/collage-detail\/\d+$/.test(path)) return "College Detail";
    if (/^\/product\/review-customer\/\d+$/.test(path)) return "Product Detail";
    if (/^\/book\/book-detail\/\d+$/.test(path)) return "Book Detail";
    if (/^\/collage\/review-customer\/\d+$/.test(path)) return "Review Customer";
    if (/^\/book\/review-customer\/\d+$/.test(path)) return "Book Reviews";
    if (/^\/college\/college-customer\/\d+$/.test(path)) return "College Review";
    if (/^\/reader-club\/reader-club-detail\/\w+$/.test(path)) return "Reader Club Detail";
    if (/^\/writer-club\/writer-club-detail\/\w+$/.test(path)) return "Writer Club Detail";
    if (/^\/edit-profile\/\w+$/.test(path)) return "Edit Profile";
  
    return "Home";
  };

  return (
    <>
      <header>
      
        <div
          className="overlay"
          onClick={() => {
            openSidebar();
          }}
          style={toggleSideBar ? { right: 0 } : { right: "100%" }}
        ></div>
        <div className="row align-items-center main-nav">
          <div className="col-4">
            <div className="logo-list">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="" className="img-fluid" />
                </Link>
              </div>
              <div
                className="list"
                style={toggleSideBar ? { right: 0 } : { right: "-200px" }}
              >
                <ul style={{display:'flex',gap:'25px'}}>
                  <li>
                  <div className="dropdown">
                    <Link to="/product">BUY</Link>
                    <ul className="dropdown-menu">
                      <li>Coming Soon</li>
                        {/* <li style={{ padding: "10px" }}>
                          <Link to="/sell">Add Product</Link>
                        </li>
                        <li
                          style={{ paddingTop: "0px", paddingBottom: "10px" }}
                        >
                          <Link to="/productlist">Product List</Link>
                        </li> */}
                      </ul>
</div>

                  </li>
                  <li>
                    <div className="dropdown">
                      <Link>SELL</Link>
                      <ul className="dropdown-menu">
                      <li>Coming Soon</li>
                        {/* <li style={{ padding: "10px" }}>
                          <Link to="/sell">Add Product</Link>
                        </li>
                        <li
                          style={{ paddingTop: "0px", paddingBottom: "10px" }}
                        >
                          <Link to="/productlist">Product List</Link>
                        </li> */}
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="dropdown">
                      <Link>Share</Link>
                      <ul className="dropdown-menu">
                      <Link to="/members">
                     
                      
                      <li>Members</li>
                      </Link>
                      <Link >
                     
                      
                      <li>My Page</li>
                      </Link>
                        {/* <li style={{ padding: "10px" }}>
                          <Link to="/sell">Add Product</Link>
                        </li>
                        <li
                          style={{ paddingTop: "0px", paddingBottom: "10px" }}
                        >
                          <Link to="/productlist">Product List</Link>
                        </li> */}
                      </ul>
                    </div>
                  </li>
                  {/* <li>
                  <div class="nav-item">
                  Categories
                  <div class="mega-dropdown">
                <div class="dropdown-column">
                    <h3>Electronics</h3>
                    <ul>
                        <li><a href="#">Laptops</a></li>
                        <li><a href="#">Smartphones</a></li>
                        <li><a href="#">Cameras</a></li>
                    </ul>
                </div>
                </div>
                  </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="search-box">
              <input
                type="text"
                placeholder="Search..."
                className="search-input"
              />
              <img src={searchIcon} className="search-icon" alt="" />
            </div>
          </div>
          <div className="col-4 navicons">
            <div className="new-nav-link">
              {token ? (
                <div className="dropdown">
                  <button
                    className={`btn dropdown-toggle ${
                      pathName.pathname === "/profile" ? "active-header" : ""
                    }`}
                    type="button"
                    id="profileDropdown"
                    aria-expanded="false"
                  >
                    <i style={{color:'black'}} className="bi bi-person-fill"></i>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="profileDropdown">
                    <li>
                      <a className="dropdown-item" href="/#/profile">
                        Profile
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/cart">
                        Cart
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/">
                        Wishlist
                      </a>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              ) : (
                <a
                  href="/#/login"
                  className={`${pathName.pathname === "/login" ? "active-header" : ""}`}
                  style={{textDecoration:'none', color:'black'}}
                >
                  LOGIN
                </a>
              )}
              {/* <Link style={{ textDecoration: "none" }} to="/cart">
                <i style={{ color: "black" }} className="bi bi-cart-fill"></i>
              </Link> */}
            </div>

            <div className="d-flex">
              <button className="bras-btn" onClick={openLeftSidebar}>
                <i style={{ fontSize: "25px" }} className="bi bi-arrow-right-circle"></i>
              </button>

              <button onClick={() => openSidebar()} className="bras-btn">
                <i style={{ fontSize: "25px" }} className="fa fa-regular fa-bars"></i>
              </button>
            </div>
          </div>
        </div>
      </header>

   
    </>
  );
}

export default Header;
