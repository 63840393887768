import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import img from "../assets/all-card.png";
import maleImg from "../assets/maleprofile.jpg";
import femaleImg from "../assets/femaleprofile.png";
import flag from "../assets/flag-in.png";
// import like from "../assets/Vector (12).png";
import following from "../assets/Vector (13).png";
import blog from "../assets/Vector (14).png";
import review from "../assets/Vector (15).png";
import chat from "../assets/Chat.png";
import question from "../assets/Vector (16).png";
import logoProfile from "../assets/question-card-img.png";
import star from "../assets/star.svg";
// import topMember from "../assets/top-member.png";
import bookReview from "../assets/book-review.png";
import adImg from "../assets/ad-img.png";
import SidebarContext from "../context/SidebarContext";
// import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../context/baseUrl";
import ScreenLoader from "../loader/ScreenLoader";
import { toast } from "react-toastify";
import MemberRightSide from "./Member/MemberRightSide";
import { capitalizeFirstLetter, capitalizeWords } from "../common/toUpperCase";
import {faGraduationCap} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewPage from "./NewPage";

function AllPage() {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  // const pathName = useLocation();
  const [member, setMember] = useState(null);
  const [loading, setLoading] = useState(false);
  const [followLoad, setFollowLoad] = useState(false);
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData")) || null
  );
  const loginuserId = localStorage.getItem("userId");
  const navigate = useNavigate();

  async function getMember() {
    setLoading(true);
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/user/getalluser`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setMember(res.data.data);
    } catch (e) {}
    setLoading(false);
  }

  const raw = { toFollowId: userId };

  const handleFollow = async (followId) => {
    setLoading(true);
    const userToken = localStorage.getItem("token");

    if (!loginuserId || !userToken) {
      toast.error("Please Login First.");
      navigate("/login");
      return;
    }
    if (followId === loginuserId) {
      return toast.error("You Can't follow yourself");
    }
    if (loginuserId === undefined || followId === undefined) {
      return toast.error("Something went Wrong");
    }

    try {
      const res = await axios.put(
        `${baseUrl}/user/updateFollower/${loginuserId}`,
        {
          toFollowId: followId,
        }
      );

      if (res?.status === 200) {
        localStorage.setItem("userData", JSON.stringify(res?.data?.data));
        setUserData(res?.data?.data);
        getMember();
      }
    } catch (error) {
      console.error("Error updating follower:", error);
    }
    setLoading(false);
  };

  const handleFollowCheck = (userId) => {
    const follow = userData?.following?.includes(userId);
    return follow || false;
  };

  useEffect(() => {
    getMember();
  }, []);

  // if (member === null) {
  //   return (
  //     <>
  //       <div className="loader-container">
  //         <span className="loader"></span>
  //       </div>
  //     </>
  //   );
  // }

  return (
    <>
      <Header />
   
     
      {loading && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          {/* <div className="d-flex align-items-center path-box">
            <p
              className="path-name m-0"
              onClick={() => {
                navigate("/");
              }}
            >
              Home /
            </p>
            <p className="path-name m-0"> &nbsp;Category / </p>
            <p className="path-name-bold m-0">&nbsp;Members</p>
          </div> */}
          <br/>
          {member &&
            member?.length > 0 &&
            member?.map((element, index) => {
              // console.log(element.userDetails)
              return (
                <>
                  <div className="all-card" key={index}>
                    <div className="row">
                      <div className="col-md-2 col-sm-12 allimg">
                        <img
                          src={element.profileImage}
                          style={{
                            height: "80px",
                            width: "80px",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                          alt=""
                          onClick={() => {
                            navigate(`/member/${element?._id}`);
                          }}
                        />
                      </div>
                      <div className="col-md-10 col-sm-12">
                        <div style={{ display: "flex",justifyContent:'space-between' }}>
                          <div>
                            <h6
                              className="all-card-title"
                              onClick={() => {
                                navigate(`/member/${element?._id}`);
                              }}
                            >
                              {capitalizeFirstLetter(element?.nickName)}
                            </h6>
                            <div className="d-flex align-items-center">
                              <p className="country-name">
                                India <img src={flag} alt="" />
                              </p>
                              <p className="country-name ps-3">
                                <img className="pe-1" src={review} alt="" />
                                {element?.rated}
                              </p>
                              <div className="">
                            <div
                              className="d-flex align-items-center pb-3"
                              onClick={() => {
                                navigate(
                                  `/member/${element?._id}?content=Books`
                                );
                              }}
                              style={{marginLeft:'10px'}}
                            >
                            <i class="bi bi-people-fill"></i>
                              <p className="all-card-text m-0">
                                Following
                                <span> {element?.following?.length || 0}</span>
                              </p>
                            </div>
                          </div>
                              {/* <p className="country-name ps-3">
                                <img className="pe-2" src={following} alt="" />
                                Following{" "}
                                <span>{element?.followers?.length}</span>
                              </p> */}
                            </div>
                            
                          </div>
                          <div>
                            {element?._id === loginuserId ? (
                              <a> &nbsp; &nbsp; &nbsp; Self</a>
                            ) : (
                              <button
                                className="follow-btn ms-3"
                                onClick={() => handleFollow(element?._id)}
                              >
                                {handleFollowCheck(element._id)
                                  ? "Following"
                                  : "Follow"}
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="member-four-options">
                        <div className="opts-1">
                        <div className="">
                            <div
                              className="d-flex align-items-center"
                              onClick={() => {
                                navigate(
                                  `/member/${element?._id}?content=Blogs`
                                );
                              }}
                            >
                              <i class="bi bi-substack"></i>
                              <p className="all-card-text m-0">
                                Blogs
                                <span> {element?.blogs || 0}</span>
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <div
                              className="d-flex align-items-center "
                              onClick={() => {
                                navigate(
                                  `/member/${element?._id}?content=Books`
                                );
                              }}
                            >
                              <i class="bi bi-book-half"></i>
                              <p className="all-card-text m-0">
                                Books
                                <span> {element?.books || 0}</span>
                              </p>
                            </div>
                          </div>
                        
                        </div>

                        <div className="opts-1">
                          <div className="">
                            <div
                              className="d-flex align-items-center "
                              onClick={() => {
                                navigate(
                                  `/member/${element?._id}?content=Quotes`
                                );
                              }}
                            >
                              <img src={chat} alt="" />
                              <p className="all-card-text m-0">
                                Quotes
                                <span> {element?.quotes || 0}</span>
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <div
                              className="d-flex align-items-center"
                              onClick={() => {
                                navigate(
                                  `/member/${element?._id}?content=Colleges`
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faGraduationCap} />
                              <p className="all-card-text m-0">
                                Colleges
                                <span> {element?.colleges || 0}</span>
                              </p>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

          {member && member.length < 1 ? "No Member" : null}
        </div>
        <MemberRightSide />
      </section>
    </>
  );
}

export default AllPage;
