import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Header from "../components/header/header";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import img1 from '../assets/product1.png'
import img2 from '../assets/product2.png'
import img3 from '../assets/product3.png'
import img4 from '../assets/product4.png'
import img5 from '../assets/product5.png'
import img6 from '../assets/product6.png'
import book from '../assets/p4.png'
import cup from '../assets/p8.png'
import book2 from '../assets/p14.png'
import book3 from '../assets/p16.png'
import book4 from '../assets/p13.png'
import book5 from '../assets/p15.png'
import logo from '../assets/logo.png'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import NewPath from '../components/header/NewPath';



function NewHome() {
  // const navigate = useNavigate();
  // import required modules


  return (

    <>

    <Header/>
   
    <br/>
<br/>
<br/>

<>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide className='slide1'>
          <img src={img1}/>
        </SwiperSlide>
        <SwiperSlide>         
         <img src={img2}/>
</SwiperSlide>
  <SwiperSlide>         
         <img src={img3}/>
</SwiperSlide>
  <SwiperSlide>         
         <img src={img4}/>
</SwiperSlide>
  <SwiperSlide>         
         <img src={img5}/>
</SwiperSlide>
  <SwiperSlide>         
         <img src={img6}/>
</SwiperSlide>
      </Swiper>
    </>


    <div className='home-aboutus'>
    <div className='home-aboutus-left'>
    <img src={book}/>

</div>
 <div className='home-aboutus-right'>
 <h2>About Us</h2>
 <p>Booksonfly is an online marketplace for intellectual content including Books, Files, Text, Audio, Video and Premium Stationary.</p>

<p>Join as a member to the platform, engage in Intellectual conversation, purchase useful stuffs and also market your own creativity to other members.</p>
<br/>
<div className='mission-div'>

  <div className='home-mission'>
  <h4>Vision</h4>
  <p>To become the premier global platform, uniting intellectuals worldwide for seamless sharing, learning and growth.</p>

  </div>
  <div className='home-mission'>
  <h4>Mission</h4>
  <p>To create a learner centric community where members can access intellectual content conveniently.</p>

  </div>
</div>
</div>

    </div>
<br/>
<br/>
<div className='ourproduct'>
<h1>Our Products<img src={cup}/></h1>
<div className='ourproduct-newcards'>
<div className='ourproduct-newcard'>
<img src={book2}/>
<h3>New Books</h3>


</div>
<div className='ourproduct-newcard'>
<img src={book3}/>
<h3>Old Books</h3>


</div>
<div className='ourproduct-newcard'>
<img src={book4}/>
<h3>Digital Books</h3>


</div>
<div className='ourproduct-newcard'>
<img src={book5}/>
<h3>Stationary</h3>


</div>
</div>


</div>
<br/>
<br/>
<div className='New-footer'>
<ul className='footlogo'>
  <img src={logo}/>
  <p>A Marketplace for Knowledge and Creativity.
  Join a global community where ideas thrive, and learning never stops.</p>
  <div className='link-media'>
<Link to='https://www.instagram.com/booksonfly.official/'>

  <li className='none' style={{fontSize:'25px'}}>                <FontAwesomeIcon  icon={faInstagram} />
</li>
</Link>
<Link to='http://www.facebook.com/booksonfly'>


  <li className='none' style={{fontSize:'25px'}}>                <FontAwesomeIcon icon={faFacebookF} />
</li>
</Link>
<Link to='https://linkedin.com/company/booksonfly'>


  <li className='none' style={{fontSize:'25px'}}>  <FontAwesomeIcon icon={faLinkedinIn} /></li>
</Link>
<Link to='https://www.booksonfly.com/'>


  <li className='none' style={{fontSize:'25px'}}>                      <FontAwesomeIcon icon={faGlobe} />

</li>
</Link>
  </div>

</ul>
<ul>
  Quick Links
  <li>Home</li>
  <li>Books</li>
  <li>Blogs</li>
  <li>Quotes</li>
  <li>Colleges</li>
  <li>Readers Club</li>
  <li>Writers Club</li>



</ul>

<ul>
Customer Policies
  <li>Privacy Policy</li>
  <li>Terms & Conditions</li>
  <li>Return & Refund </li>
  <li>Shipping </li>
  <li>FAQ</li>
  



</ul>
</div>

<div className='ALLRIGHTSRESERVED'>
<p>BOOKSONFLY © 2023. ALL RIGHTS RESERVED</p>
</div>
    </>
  )
}

export default NewHome


  