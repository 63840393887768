import React, { useContext, useState } from "react";
// import book from "../../assets/book-review-img.png";
// import star from "../../assets/star.svg";
import Rating from "../Rating";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Img from "../../assets/question-card-img.png";
import LoginContext from "../../context/login/LoginContext";
import BookContext from "../../context/book/BookContext";
import { capitalizeWords } from "../../common/toUpperCase";
import { baseUrl } from "../../context/baseUrl";

function BookReviewCard({
  index,
  heading,
  bookId,
  description,
  likes,
  likesCount,
  comments,
  like,
  book:bookData
}) {
  const navigate = useNavigate();
  const book = useContext(BookContext);
  const login = useContext(LoginContext);
  const { loggedIn } = login;
  const { bookComment, setBookComment, bookLike, setBookLike } = book;
  const [newComment, setNewComment] = useState(false);
  const [newLike, setNewLike] = useState(false);
  const [commentBox, setCommentBox] = useState(false);
  const [comment, setComment] = useState("");
  function toggleCommentBox() {
    setCommentBox(!commentBox);
  }
  console.log(bookId);
  async function handleOnComment(e) {
    e.preventDefault();
    if (loggedIn === true) {
      const id = e.target.id;
      try {
        const body = JSON.stringify({
          comment: comment,
        });
        const res = await axios({
          method: "put",
          url: `${baseUrl}/comments/postcomment/${id}`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setComment("");
        setBookComment(res.data.data.commentArray);
        setNewComment(true);
      } catch (e) {}
    } else {
      navigate("/login");
    }
  }
  async function handleOnLike(id) {
    if (loggedIn === true) {
      try {
        const body = JSON.stringify({
          contentId: id,
        });
        const res = await axios({
          method: "put",
          url: `https://books-on-fly.vercel.app/api/comments/updatelikes/${id}`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setBookLike(res.data.data.likes);
        setNewLike(true);
      } catch (e) {}
    } else {
      navigate("/login");
    }
  }

  let allComment;
  if (newComment === false) {
    allComment = comments.map((element, index) => {
      return (
        <>
          <div className="d-flex">
            <img src={Img} alt="" className="comment-profile" />
            <div className="ms-3">
              <p className="comment-text">{element.comment}</p>
            </div>
          </div>
        </>
      );
    });
  } else {
    allComment = bookComment.map((element, index) => {
      return (
        <>
          <div className="d-flex">
            <img src={Img} alt="" className="comment-profile" />
            <div className="ms-3">
              <p className="comment-text">{element.comment}</p>
            </div>
          </div>
        </>
      );
    });
  }
  return (
    <>
      <div className="col-sm-12 col-md-6 col-lg-3" key={index}>
        <div
          className="book-review-box text-center"
          style={
            commentBox === true
              ? { marginBottom: "0px" }
              : { marginBottom: "18px" }
          }
        >
          <Link to={`/book/book-detail/${bookId}`}>
            <div className="text-center">
              <img src={bookData?.displayImage||""} style={{ margin: "auto" }} alt="" width={"100px"}/>
            </div>
            <h6 className="book-review-title">{capitalizeWords(heading)}</h6>
          </Link>
          {/* <p className="book-review-text m-0">{description}</p> */}
          {/* <div dangerouslySetInnerHTML={{ __html: description }}></div> */}
          <div className="review-total d-flex justify-content-center align-item-center">
          <Rating rating={(like?.rating||0)/(like?.noOfRates)} />
           {like?.noOfRates>0 && like?.noOfRates}
          </div>
          <div className="d-flex mt-2 justify-content-center">
            <div
              className="d-flex align-items-center me-4"
              onClick={() => handleOnLike(bookId)}
              style={{ cursor: "pointer" }}
            >
              {/* {likes.includes(localStorage.getItem("userId")) ? (
                <i className="fa fa-solid fa-heart"></i>
              ) : (
                <i className="bi bi-heart"></i>
              )}
              {newLike === false
                ? likesCount + " " + "Like"
                : bookLike.length + " " + "Like"} */}
            </div>
            {/* <div
              className="d-flex align-items-center book-review-comment"
              style={{ cursor: "pointer" }}
              onClick={toggleCommentBox}
            >
              <i className="bi bi-chat me-2"></i>
              
              Comment
            </div> */}
          </div>
        </div>
        {commentBox && (
          <div className="comment-boxes">
            <div className="d-flex align-items-center">
              {/* <img src={Img} alt="" className="comment-profile" /> */}
              <input
                className="w-100 me-3"
                value={comment}
                style={{ padding: "6px 10px", fontSize: "10px" }}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Add your comment....."
              />
              <button
                className="blog-comment-btn"
                style={{ padding: "6px 10px", fontSize: "10px" }}
                id={bookId}
                onClick={(e) => handleOnComment(e)}
              >
                Comment
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BookReviewCard;
