import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import bookCustomer from "../assets/book-customer.png";
import star from "../assets/star.svg";
import starEmpty from "../assets/star-empty.svg"; // Import the empty star SVG
import reviewWrite from "../assets/review-write.png";
import CustomerReviewCard from "../components/book-review/CustomerReviewCard";
import RightCustomerCard from "../components/book-review/RightCustomerCard";
import SidebarContext from "../context/SidebarContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../context/baseUrl";
import { capitalizeParagraph, capitalizeWords } from "../common/toUpperCase";
import Rating from "../components/Rating";

function CollegeReview() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const navigate = useNavigate();
  let params = useParams();
  const bookId = params.id;
  const [allReview, setAllreview] = useState([]);
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0); // State to track the selected star rating
  const [content, setContent] = useState(null);

  const getAllReview = async () => {
    try {
      const res = await axios.get(`${baseUrl}/review/bycontent/${bookId}`);
      console.log(res);
      setAllreview(res?.data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllReview();
  }, []);

  useEffect(() => {
    async function fetchContent() {
      try {
        const response = await axios.get(
          `${baseUrl}/content/getacontent/${bookId}`
        );
        setContent(response?.data?.data); // Assuming the response.data is the content object
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    }

    fetchContent();
  }, [bookId]);

  const handReview = async () => {
    if (comment === "") {
      return alert("Please Write Comment");
    }
    try {
      const res = await axios.post(
        `${baseUrl}/review`,
        { content: bookId, comment, rating },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(res);
      if (res?.status === 200) {
        alert("Review Post is Done.");
        setComment("");
        setRating(0); // Reset rating after successful submission
        getAllReview();
      }
    } catch (error) {}
  };

  const onDelete = async (id) => {
    try {
      const res = await axios.delete(`${baseUrl}/review/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log(res);
      if (res?.status === 200) {
        alert("Review Deleted Successfully.");
        getAllReview();
      }
    } catch (error) {}
  };

  const handleStarClick = (index) => {
    setRating(index + 1); // Set rating from 1 to 5
  };

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          {/* <div className="d-flex align-items-center path-box">
            <p
              className="path-name m-0"
              onClick={() => {
                navigate("/");
              }}
            >
              Home /
            </p>
            <p className="path-name m-0" onClick={() => navigate("/colleges")}>
              &nbsp;College Review /
            </p>
            <p className="path-name-bold m-0">&nbsp; Review Customer</p>
          </div> */}
          <br/>
          <div className="d-flex book-flex">

           <div className="d-flex ">
           <img
              src={content?.displayImage || bookCustomer}
              alt=""
              width={"150px"}
            />
            <div className="review-customer-box">
              <p className="book-customer-text mb-2">
                {capitalizeWords(content?.nickName)}{" "}
                {capitalizeWords(content?.userId?.country)}
              </p>
              <h2 className="book-customer-title">
                {capitalizeWords(content?.heading)}
              </h2>
              <p className="book-customer-text mb-2">
                {capitalizeWords(content?.state)}{" "}
                {capitalizeWords(content?.country)}
              </p>
              
              <div className="d-flex">
                {/* {[...Array(5)].map((_, index) => (
                  <img
                    key={index}
                    src={index < content?.rating ? star : starEmpty}
                    alt=""
                  />
                ))} */}
                 <Rating rating={(content?.rating||0)/(content?.noOfRates)} />
                <p className="rating-total">{content?.noOfRates>0 && content?.noOfRates} rating</p>
              </div>

            </div>
           </div>

              <button className="Writeareview" onClick={() => setIsModalOpen(true)}>Write a review</button>
         
         
              {isModalOpen && (
        <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="">
            <div className="">
              <h5 className="review-bottom-title">Review this College </h5>
              <p className="review-bottom-text">
                Share your thoughts with other customers
              </p>
              <div className="d-flex">
                {[...Array(5)].map((_, index) => (
                  <img
                    key={index}
                    src={index < rating ? star : starEmpty}
                    alt=""
                    onClick={() => handleStarClick(index)}
                    style={{ cursor: "pointer" }}
                  />
                ))}
              </div>
              <div className="review-bottom-write-box" style={{marginTop:'15px'}}>


              <textarea  placeholder="Write a product review"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}></textarea>
                {/* <input
                  type="text"
                  className="review-write-input"
                 
                /> */}
                {/* <img
                  src={reviewWrite}
                  className="review-input-img"
                  alt=""
                  onClick={handReview}
                /> */}
              </div>
            </div>
            <button 
                    onClick={handReview}
                    className="close-modal-button">
                Submit
              </button>
          </div>
          </div>
        </div>
      )}
         
         
         
         
          </div>
          <h2 className="review-title">Review</h2>
          {allReview?.map((review) => (
            <CustomerReviewCard
              key={review._id}
              review={review}
              onDelete={onDelete}
            />
          ))}
          <br />
          <br />
          <br />
          {/* <div className="review-bottom-box">
            <div className="review-bottom">
              <h5 className="review-bottom-title">Review this College </h5>
              <p className="review-bottom-text">
                Share your thoughts with other customers
              </p>
              <div className="d-flex">
                {[...Array(5)].map((_, index) => (
                  <img
                    key={index}
                    src={index < rating ? star : starEmpty}
                    alt=""
                    onClick={() => handleStarClick(index)}
                    style={{ cursor: "pointer" }}
                  />
                ))}
              </div>
              <div className="review-bottom-write-box">
                <input
                  type="text"
                  className="review-write-input"
                  placeholder="Write a product review"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <img
                  src={reviewWrite}
                  className="review-input-img"
                  alt=""
                  onClick={handReview}
                />
              </div>
            </div>
          </div> */}
        </div>
        <div className="right-box">
        <br/>
          <h2 className="right-title">Products related to this item</h2>
          <div className="right-customer-box">
            <RightCustomerCard contentType={"Colleges"} />
            {/* <RightCustomerCard />
            <RightCustomerCard />
            <RightCustomerCard /> */}
          </div>
        </div>
      </section>
      <style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 8px;
          position: relative;
          width: 80%;
          max-width: 500px;
        }
        .close-modal-button {
          margin-top: 15px;
          padding: 5px 10px;
          border: none;
          background: #d9534f;
          color: white;
          border-radius: 5px;
          cursor: pointer;
        }
      `}</style>
    </>
  );
}

export default CollegeReview;
