import React, { useContext, useEffect, useState } from "react";
import blogDetail from "../../assets/blog-default.png";
import flag from "../../assets/flag-in.png";
import blogProfile from "../../assets/question-card-img.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import LoginContext from "../../context/login/LoginContext";
import BlogContext from "../../context/blog/BlogContext";
import { baseUrl } from "../../context/baseUrl";
import { capitalizeParagraph, capitalizeWords } from "../../common/toUpperCase";
// import BlogModal from "./BlogModal";

function BlogDetailCard() {
  const login = useContext(LoginContext);
  const blogComments = useContext(BlogContext);
  const { loggedIn } = login;
  const { blogComment, setBlogComment, blogLike, setBlogLike } = blogComments;

  const params = useParams();
  const navigate = useNavigate();

  const [commentBox, setCommentBox] = useState(true);
  const [newComment, setNewComment] = useState(false);
  const [newLike, setNewLike] = useState(false);
  const [blog, setBlog] = useState(null);
  const [comment, setComment] = useState("");
  const [loader, setLoader] = useState(false);

  function toggleCommentBox() {
    setCommentBox(!commentBox);
  }

  console.log(blog);

  async function getBlogs() {
    setLoader(true);
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/content/getacontent/${params.blogId}`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setBlog([res.data.data]);
    } catch (e) {}
    setLoader(false);
  }
  useEffect(() => {
    getBlogs();
  }, []);

  async function handleOnComment() {
    if (loggedIn === true) {
      setLoader(true);
      try {
        const body = JSON.stringify({
          comment: comment,
        });
        const res = await axios({
          method: "put",
          url: `${baseUrl}/comments/postcomment/${params.blogId}`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setComment("");
        setBlogComment(res.data.data.commentArray);
        setNewComment(true);
      } catch (e) {}
      setLoader(false);
    } else {
      navigate("/login");
    }
  }
  async function handleOnLike(id) {
    if (loggedIn === true) {
      setLoader(true);
      try {
        const body = JSON.stringify({
          contentId: id,
        });
        const res = await axios({
          method: "put",
          url: `${baseUrl}/comments/updatelikes/${id}`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setBlogLike(res.data.data.likes);
        setNewLike(true);
      } catch (e) {}
      setLoader(false);
    } else {
      navigate("/login");
    }
  }

  if (blog === null) {
    return <></>;
  }

  return (
    <>
      {blog.map((element, index) => {
        let allComment;
        if (newComment === false) {
          allComment = element.commentArray.map((element, index) => {
            return (
              <>
                <div className="d-flex">
                  {/* <img src={Img} alt="" className="comment-profile" /> */}
                  <div className="ms-3 d-flex">
                    <p className="comment-text">{element.comment}</p> &nbsp;
                    <p className="comment-text">{element.nickName}</p>
                  </div>
                </div>
              </>
            );
          });
        } else {
          allComment = blogComment.map((element, index) => {
            return (
              <>
                <div className="d-flex">
                  {/* <img src={Img} alt="" className="comment-profile" /> */}
                  <div className="ms-3">
                    <p className="comment-text">{element.comment}</p>
                    <p className="comment-text">{element.nickName}</p>
                  </div>
                </div>
              </>
            );
          });
        }
        return (
          <>
            <div className="blog-detail-card">
              <h1 className="blog-detail-title">
                {capitalizeParagraph(element.heading)}
              </h1>
              <div className="d-flex justify-content-between align-items-center pb-3">
                <div className="d-flex align-items-center">
                  <div>
                    <img
                      src={element?.userId?.profileImage || blogProfile}
                      className="pe-2"
                      alt=""
                      style={{
                        width: "65px",
                        height: "57px",
                        borderRadius: "100px",
                      }}
                    />
                  </div>
                  <div>
                    <h6 className="m-0">
                      {capitalizeWords(element?.userId?.nickName)}
                    </h6>
                    <div className="d-flex align-items-center">
                      <p className="m-0 ">
                        {" "}
                        {capitalizeWords(element?.userId?.country) || "India"}
                      </p>{" "}
                      <img className="ps-2" src={flag} alt="" />
                    </div>
                  </div>
                </div>
                <div className="blog-date">
                  {capitalizeWords(element?.author)}&nbsp;{" "}
                  {moment(element.createdAt).format("DD MMM YYYY")}
                </div>
              </div>
              <div className="blog-detail-img">
                <img src={element?.displayImage || blogDetail} alt="" />
              </div>

              <p className="blog-detail-description pt-3">
                {/* {element.description} */}
                <div
                  dangerouslySetInnerHTML={{ __html: element?.description }}
                ></div>
              </p>
              <div className="d-flex align-items-center">
                <div
                  className="like d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOnLike(params.blogId)}
                >
                  {element.likes.includes(localStorage.getItem("userId")) ? (
                    <i className="fa fa-solid fa-heart"></i>
                  ) : (
                    <i className="bi bi-heart"></i>
                  )}
                  {newLike === false
                    ? element.likes.length + " " + "Like"
                    : blogLike.length + " " + "Like"}
                </div>
                <div className="comment d-flex align-items-center">
                  <button
                    type="button"
                    className="comment-btn"
                    onClick={toggleCommentBox}
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                  >
                    <i className="bi bi-chat"></i>
                    {element.commentArray.length} Comment
                  </button>
                </div>
              </div>
            </div>
            <div
              className="blog-detail-comment"
              style={{ display: commentBox ? "block" : "none" }}
            >
              {allComment}
              <div className="comment-boxes blog-comment-box">
                <div className="d-flex align-items-center">
                  <input
                    className="w-100"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Add your comment....."
                  />
                  <button
                    className="blog-comment-btn"
                    onClick={() => handleOnComment()}
                  >
                    Comment
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      })}
      {loader && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
    </>
  );
}

export default BlogDetailCard;
